import "./set-public-path";

const favoritosDev = "http://localhost:8203";
const lamDev = "http://localhost:49461";
const lhlAdminDev = "http://localhost:8201";
const s3Dev = "http://localhost:8202";
const catalogDev = "http://localhost:8200";
const tokenDev = "http://localhost:8190";

const lamProd = "https://lam.finder.lexis.com.ec";
/*const lhlAdminProd = "https://serviceszuul.lexis.com.ec/lhladminbe"
const s3Prod = "https://serviceszuul.lexis.com.ec/lhls3adminbe"
const catalogProd = "https://serviceszuul.lexis.com.ec/lhlcatalogbe"
const favoritosProd = "https://serviceszuul.lexis.com.ec/lhlfavoritosbe"
const tokenProd = "https://servicesltg.lexis.com.ec"*/

// const lhlAdminProd = "https://serviceslhladmin.lexis.com.ec"
// const s3Prod = "https://serviceslhladmin.lexis.com.ec"
// const catalogProd = "https://serviceslhladmin.lexis.com.ec"
// const favoritosProd = "https://serviceslhladmin.lexis.com.ec"
// const tokenProd = "https://servicesltg.lexis.com.ec"

const lhlAdminProd = "https://servicessecure.lexis.com.ec";
const s3Prod = "https://servicessecure.lexis.com.ec";
const catalogProd = "https://servicessecure.lexis.com.ec";
const favoritosProd = "https://servicessecure.lexis.com.ec";
// const tokenProd = "https://servicesltg.lexis.com.ec";

let entorno = "prod";
let catalogUrl, lhlUrl, s3Url, favoritosUrl, lamUrl, tokenUrl;
if (entorno === "dev") {
  catalogUrl = catalogDev;
  lhlUrl = lhlAdminDev;
  s3Url = s3Dev;
  lamUrl = lamProd;
  favoritosUrl = favoritosProd;
  tokenUrl = tokenDev;
} else {
  catalogUrl = catalogProd;
  lhlUrl = lhlAdminProd;
  s3Url = s3Prod;
  lamUrl = lamProd;
  favoritosUrl = favoritosProd;
  //   tokenUrl = tokenProd;
}

// Define states
const apiStates = {
  LOADING: "LOADING",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
};

//-----LHL catalog endpoints-------

//Services endpoints
const categoriesByAccessTypeUrl =
  catalogUrl + "/api/v1/lhl/servicio/findbyname";

//Categories endpoints
const lhlCategoriesUrl = catalogUrl + "/api/v1/lhl/categorias"; //Find all categories
const lhlCategoryUrl = catalogUrl + "/api/v1/lhl/categoria"; //Find an existing category y id

//Subcategories endpoints
const lhlSubcategoriesUrl = catalogUrl + "/api/v1/lhl/subcategorias"; //Find all subcategories
const lhlSubcategoryUrl = catalogUrl + "/api/v1/lhl/subcategoria"; //Find an existing subcategory by id

//Subjects endpoints
const lhlMateriasUrl = catalogUrl + "/api/v1/lhl/materias"; //Find all subjects
const lhlMateriaUrl = catalogUrl + "/api/v1/lhl/materia"; //Find an existing subjects by id

//Countries endpoints
const lhlPaisesUrl = catalogUrl + "/api/v1/lhl/paises";

//-------LHL Admin endpoints----------
const lhlFindLibrosUrl = lhlUrl + "/api/v1/lhl/findLibros";
const lhlFindAllLibrosUrl = lhlUrl + "/api/v1/lhl/findAll";
const lhlDownloadLibroUrl = lhlUrl + "/api/v1/lhl/downloadLibro";

//-------- S3 admin endpoints----------------
const s3DownloadUrl = s3Url + "/api/v1/lhl/s3/download";

//-------- Favoritos endpoint ---------------
const lhlFindFavoritosUrl = favoritosUrl + "/api/v1/lhl/favoritos/libros";
const lhlSaveFavoritoUrl = favoritosUrl + "/api/v1/lhl/favoritos/save";
const lhlDeleteFavoritoUrl = favoritosUrl + "/api/v1/lhl/favoritos/delete";

//-------LAM endpoints----------
const lamValidateTokenUrl = lamUrl + "/api/v1/auth/validateToken";

//-------Token endpoints----------
const generateTokenUrl = tokenUrl + "/api/v1/lextoken/login";

//fetch functions
//Find categories by service
export const categoriesByAccessType = async (servicio) => {
  let body = { servicio };
  let response = await fetchData(categoriesByAccessTypeUrl, body);
  //console.log("Api response:", response)
  return response;
};

//fetch categories
export const lhlCategories = async () => {
  let response = await fetchData(lhlCategoriesUrl, null);
  //console.log("Api response:", response)
  return response;
};

//Find category
export const lhlFindCategory = async (id) => {
  let body = { id };
  let response = await fetchData(lhlCategoryUrl, body);
  //console.log("Api response:", response)
  return response;
};

//fetch subcategories
export const lhlSubcategories = async () => {
  let response = await fetchData(lhlSubcategoriesUrl, null);
  //console.log("Api response:", response)
  return response;
};

//Find category
export const lhlFindSubcategory = async (id) => {
  let body = { id };
  let response = await fetchData(lhlSubcategoryUrl, body);
  //console.log("Api response:", response)
  return response;
};

//fetch subjects
export const lhlMaterias = async () => {
  let response = await fetchData(lhlMateriasUrl, {});
  //console.log("Api response:", response)
  return response;
};

//Find subject
export const lhlFindMateria = async (id) => {
  let body = { id };
  let response = await fetchData(lhlMateriaUrl, body);
  //console.log("Api response:", response)
  return response;
};

//Find countries
export const lhlFindPaises = async () => {
  let response = await fetchData(lhlPaisesUrl, null);
  // console.log("Api response:", response);
  return response;
};

//find all documents
export const lhlFindAllLibros = async () => {
  let response = await fetchData(lhlFindAllLibrosUrl, null);
  //console.log("Api response:", response)
  return response;
};
//find documents per category
export const lhlFindLibros = async (idCategoria) => {
  let body = { idCategoria };
  let response = await fetchData(lhlFindLibrosUrl, body);
  //console.log("Api response:", response)
  return response;
};

export const lhlDownloadLibro = async (id) => {
  let body = { id };
  let response = await fetchData(lhlDownloadLibroUrl, body);
  //console.log("Api response:", response)
  return response;
};

export const s3DownloadLibro = async (fileName) => {
  let body = { fileName };
  let response = await fetchData(s3DownloadUrl, body);
  //console.log("Api response:", response)
  return response;
};

export const lhlFindFavoritos = async (usuario) => {
  let body = { usuario };
  let response = await fetchData(lhlFindFavoritosUrl, body);
  //console.log("Api response:", response)
  return response;
};

export const lhlSaveFavorito = async (usuario, idDocumento, titulo) => {
  let body = { usuario, idDocumento, titulo };
  let response = await fetchData(lhlSaveFavoritoUrl, body);
  //console.log("Api response:", response)
  return response;
};

export const lhlDeleteFavorito = async (id) => {
  let body = { id };
  let response = await fetchData(lhlDeleteFavoritoUrl, body);
  //console.log("Api response:", response)
  return response;
};

// export const lamValidateToken = async (token) => {
//   let body = { token };
//   let response = await fetchData(lamValidateTokenUrl, body);
//   //console.log("Api response:", response)
//   return response;
// };

// export const generateToken = async () => {
//   let body = {
//     email: "patricioavila@lexis.com.ec",
//     servicio: "TOTAL",
//   };
//   let response = await fetchData2(generateTokenUrl, body);

//   //console.log("Api response:", response)
//   return response;
// };

//This function fetches data from server using security token

export const fetchData = async (url, bodyQuery) => {
  var fetchedData = "";

  await fetch(url, {
    method: "post",
    body: JSON.stringify(bodyQuery),
    headers: {
      "Content-Type": "application/json", //,
      //"Authorization" :token.access_token
    },
  })
    .then((response) =>
      //console.log(response),
      response.ok
        ? response.json()
        : (fetchedData = {
            state: apiStates.ERROR,
            error: response.status,
            data: null,
          })
    )
    .then((data) => {
      if (data.state !== "ERROR")
        fetchedData = {
          state: apiStates.SUCCESS,
          data,
        };
      //console.log(data)
    })
    .catch((err) => {
      //console.log(err)
      fetchedData = {
        state: apiStates.ERROR,
        error: err.status,
        data: null,
      };
    });

  return fetchedData;
};

//This function fetches data from server without security token
export const fetchData2 = async (url, bodyQuery) => {
  var fetchedData = "";

  //console.log("previous to fetch")
  await fetch(url, {
    method: "post",
    body: JSON.stringify(bodyQuery),
    headers: { "Content-Type": "application/json" },
  })
    .then((response) =>
      //console.log(response),
      response.ok
        ? response.json()
        : (fetchedData = {
            state: apiStates.ERROR,
            error: response.status,
            data: null,
          })
    )
    .then((data) => {
      if (data.state !== "ERROR") fetchedData = data;
      //console.log(data)
    })
    .catch((err) => {
      //console.log(err)
      fetchedData = {
        state: apiStates.ERROR,
        error: err.status,
        data: null,
      };
    });

  return fetchedData;
};

// Anything exported from this file is importable by other in-browser modules.
export function publicApiFunction() {}
